import { createReducer, on } from '@ngrx/store';
import { setShowDashboardLink } from './sidebar.actions';

export const sidebarFeatureKey = 'sidebar';

export interface SidebarState {
    showDashboardLink: boolean;
}

const initialState: SidebarState = {
    showDashboardLink: false,
};

export const sidebarReducer = createReducer<SidebarState>(
    initialState,
    on(setShowDashboardLink, (state, { showDashboardLink }) => ({
        ...state,
        showDashboardLink,
    })),
);
