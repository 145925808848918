import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SidebarState, sidebarFeatureKey } from './sidebar.reducer';

const selectSidebarFeature =
    createFeatureSelector<SidebarState>(sidebarFeatureKey);

export const selectShowDashboardLink = createSelector(
    selectSidebarFeature,
    (state) => state.showDashboardLink,
);
